.SiteMap {
    height: 100vh;
    width: 100vw;
    .Header {
        flex-direction: column;
        height: auto;
        width: 100%;
        margin: 0;
        position: fixed;
        top: 0px;
        z-index: 4;
        .HeaderTop {
            display: flex;
            align-items: center;
            height: 50px;
            width: 100%;
            padding: 0px 30px;
            background-color: #1c1e21;
            h2 {
                display: flex;
                align-items: center;
                color: #ffffff;
                font-size: 20px;
                font-size: 400;
                transform: scale(0.9, 1);
                span {
                    color: #2ea8ee;
                }
                img {
                    filter: invert(0%);
                    height: 30px;
                    width: 35px;
                    padding: 5px;
                    margin-right: 15px;
                    border: 1px solid red;
                    border-radius: 50%;
                }
            }
        }
        .HeaderBottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            width: 100%;
            padding: 0px 30px;
            background-color: #eeeeee;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            .HeaderInfo {
                h1 {
                    color: #1c1e21;
                    font-size: 25px;
                    font-size: 400;
                    transform: scale(0.9, 1.7);
                    // background-color: red;
                    span {
                        color: #2ea8ee;
                    }
                }
            }
            .HeaderMenu {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 40px;
                    height: 40px;
                    // filter: invert(100%);
                    cursor: pointer;
                }
                a {
                    background-color: #2ea8ee;
                    padding: 10px 20px;
                    border-radius: 5px;
                    color: white;
                    margin-right: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 15px;
                }
            }
            .HeaderMainMenu {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                position: absolute;
                min-width: 100%;
                height: auto;
                background-color: #ffffff;
                // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 0px 0px 0px 50px;
                top: 80px;
                left: 0px;
                padding-top: 30px;
                a {
                    text-decoration: none;
                    padding: 10px;
                    text-align: center;
                    cursor: pointer;
                    font-size: 20px;
                    margin-top: 10px;
                    font-weight: 500;
                    color: #1c1e21d8;
                    width: 100%;
                }
                a:hover {
                    background-color: #1c1e211d;
                }
                .HeaderMainMenuMore {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    width: 100%;
                    padding: 40px 150px;
                    gap: 40px;
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #2ea8ee;
                        padding: 15px 30px;
                        border-radius: 0px;
                        color: white;
                        cursor: pointer;
                        text-decoration: none;
                        font-size: 15px;
                        max-width: 400px;
                        img {
                            width: 20px;
                            height: 20px;
                            margin-left: 10px;
                            filter: invert(100%);
                        }
                    }
                }
            }
        }
    }
    .WebSite {
        background-color: #1c1e21;
        .MainBanner {
            max-width: 100vw;
            height: 80vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            padding: 100px 30px;
            background-color: green;
            .MainBannerInfo {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 2;
                h1 {
                    color: white;
                    text-align: center;
                    font-size: 50px;
                    span {
                        color: #2ea8ee;
                    }
                }
                h2 {
                    color: white;
                    text-align: center;
                    font-weight: 400;
                    margin-top: 50px;
                }
                a {
                    background-color: #2ea8ee;
                    padding: 20px 50px;
                    border-radius: 15px;
                    margin-top: 50px;
                    color: white;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 25px;
                }
            }
            .MainBannerImg {
                width: 100vw;
                min-height: 80vh;
                position: absolute;
                .MainBannerImgDark {
                    position: absolute;
                    top: 0px;
                    background-color: black;
                    width: 100%;
                    height: 100%;
                }
                img {
                    position: absolute;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    object-fit: cover;
                    opacity: 40%;
                }
            }
        }
        .SiteMapList {
            padding: 50px;
            ul {
                h1 {
                    color: white;
                    font-weight: 600;
                    font-size: 30px;
                    margin-top: 40px;
                    padding-bottom: 10px;
                    cursor: pointer;
                }
                li {
                    color: #d4d4d4;
                    font-weight: 400;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    .Footer {
        background-color: #1c1e21;
        .FooterFormMain {
            padding: 100px 50px;
            .FooterFormTitle {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                h1 {
                    color: white;
                }
            }
            .FooterForm {
                form {
                    h3 {
                        color: white;
                        font-weight: 500;
                        font-size: 20px;
                        margin-top: 15px;
                    }
                    input,
                    button {
                        margin-top: 10px;
                        width: 100%;
                        height: 40px;
                        border-radius: 5px;
                        border: none;
                    }
                    input:last-child,
                    button {
                        background-color: #2ea8ee;
                        color: white;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
        .FooterContactUs {
            .FooterContactUsTitle {
                padding: 0px 50px;

                h2 {
                    color: white;
                    font-weight: 600;
                    font-size: 30px;
                }
                h3 {
                    color: #d4d4d4;
                    font-weight: 400;
                    margin-top: 20px;
                }
            }
            .FooterContactUsInfo {
                padding: 0px 50px;
                margin: 40px 0px 80px 0px;
                .FooterContactUsInfoBox {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    img {
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                    }
                    h2 {
                        color: #d4d4d4;
                        font-weight: 400;
                        span,
                        a {
                            text-decoration: none;
                            color: #2ea8ee;
                            font-weight: 400;
                            margin-top: 20px;
                        }
                    }
                }
            }
            .FooterContactUsAbout {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-color: #1c1e21;
                height: 300px;
                width: 100%;
                margin-top: 50px;
                padding-bottom: 80px;
                h2 {
                    font-size: 18px;
                    font-weight: 400;
                    color: white;
                }
                h3 {
                    font-size: 18px;
                    font-weight: 400;
                    border-top: 1px solid white;
                    padding-top: 30px;
                    margin-top: 30px;
                    color: white;
                }
            }
        }
    }
    .CallNow {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2ea8ee;
        bottom: 0;

        cursor: pointer;
        width: 100%;
        padding: 20px 50px;
        z-index: 5;
        h1 {
            color: white;
            font-size: 25px;
        }
    }
    .BookAnAppointmentButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2ea8ee;
        border: 2px solid white;
        cursor: pointer;
        width: 40px;
        height: 150px;
        position: fixed;
        top: 60%;
        left: 0;
        scale: rotate(30px);
        z-index: 10;
        h1 {
            font-size: 14px;
            color: white;
            writing-mode: vertical-rl;
        }
    }
}
@media screen and (min-width: 1400px) {
    .SiteMap {
        .Header {
            .HeaderTop {
                padding: 0px 15%;
            }
            .HeaderBottom {
                padding: 0px 15%;
            }
        }
        .WebSite {
            .MainBanner {
                padding: 0px 15%;
            }
            .SiteMapList {
                padding: 0px 15%;
            }
        }
        .Footer {
            .FooterFormMain {
                padding: 100px 15%;
            }
            .FooterContactUs {
                .FooterContactUsTitle {
                    padding: 0px 15%;
                }
                .FooterContactUsInfo {
                    padding: 0px 15%;
                }
                .FooterContactUsAbout {
                    padding: 0px 15%;
                }
            }
        }
        // display: none;
    }
}
@media screen and (max-width: 800px) {
    .SiteMap {
        // display: none;
        .Header {
            .HeaderTop {
                // padding: 0px 15%;
            }
            .HeaderBottom {
                // padding: 0px 15%;
            }
        }
        .WebSite {
            .MainBanner {
                // min-height: 90vh;
                padding: 100px 30px;
            }
            .SiteMapList {
                padding: 30px;
                .Services1,
                .Services2,
                .Services3 {
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .Footer {
            .FooterFormMain {
                padding: 100px 30px;
            }
            .FooterContactUs {
                .FooterContactUsTitle {
                    padding: 0px 30px;
                }
                .FooterContactUsInfo {
                    padding: 0px 30px;
                }
                .FooterContactUsAbout {
                    padding: 0px 30px;
                }
            }
        }
    }
}
@media screen and (max-width: 550px) {
    .SiteMap {
        // display: none;
        .Header {
            .HeaderTop {
                padding: 20px 5px;
            }
            .HeaderBottom {
                height: 60px;
                padding: 10px 10px;
                .HeaderInfo {
                    h1 {
                        font-size: 15px;
                    }
                }
                .HeaderMenu {
                    img {
                        width: 30px;
                        height: 30px;
                    }
                    a {
                        margin-right: 20px;
                        font-size: 13px;
                    }
                }
                .HeaderMainMenu {
                    width: 100%;
                    top: 110px;
                    padding-top: 30px;
                    border-radius: 0px 0px 0px 20px;
                    .HeaderMainMenuMore {
                        flex-direction: column;
                        width: 100%;
                        padding: 20px 20px 40px 20px;
                        gap: 10px;
                        a {
                            padding: 15px 10px;
                            border-radius: 5px;
                            font-size: 15px;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .WebSite {
            .MainBanner {
                // min-height: 90vh;
                padding: 100px 30px;
                .MainBannerInfo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    z-index: 2;
                    h1 {
                        font-size: 30px;
                    }
                    h2 {
                        font-size: 20px;
                    }
                }
            }
            .SiteMapList {
                padding: 30px;
                .Services1,
                .Services2,
                .Services3 {
                    margin: 50px 0px 70px 0px;

                    img {
                        width: 100%;
                        height: 300px;
                    }
                    h2 {
                        font-size: 20px;
                    }
                }
            }
        }
        .Footer {
            .FooterFormMain {
                padding: 100px 30px;
            }
            .FooterContactUs {
                .FooterContactUsTitle {
                    padding: 0px 30px;
                    h2 {
                        font-size: 19px;
                    }
                    h3 {
                        font-size: 15px;
                    }
                }
                .FooterContactUsInfo {
                    padding: 0px 30px;
                    .FooterContactUsInfoBox {
                        h2 {
                            font-size: 15px;
                        }
                    }
                }
                .FooterContactUsAbout {
                    padding: 0px 30px;
                    h2 {
                        font-size: 14px;
                    }
                    h3 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
